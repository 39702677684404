import { request } from "auth/FetchInterceptor";
import { useMutation, useQuery } from "react-query";
import { dispatch } from "redux/store";
import {
  login as loginAction,
  logout as logoutAction,
} from "../redux/authentication/auth";
import { useSelector } from "react-redux";

const login = (data) => {
  return request({
    url: `${process.env.REACT_APP_API}/v2/mastermind/sign-in`,
    method: "post",
    data,
  });
};

const signUp = (data) => {
  return request({
    url: `${process.env.REACT_APP_API}/v1/auth/signup/salesRep`,
    method: "post",
    data,
  });
};

const restrictedSignup = (data) => {
  return request({
    url: `${process.env.REACT_APP_API}/v1/auth/restricted-signup`,
    method: "post",
    data,
  });
};

const invite = (data) => {
  return request({
    url: `${process.env.REACT_APP_API}/v1/auth/signupInvite/salesRep`,
    method: "post",
    data,
  });
};

const verifyEmail = (data) => {
  return request({
    url: `${process.env.REACT_APP_API}/v1/auth/accountVerification`,
    method: "post",
    data,
  });
};

const resendVerifyEmail = (data) => {
  return request({
    url: `${process.env.REACT_APP_API}/v1/auth/emailConfirmation`,
    method: "post",
    data,
  });
};

const profileUpdate = (data) => {
  return request({
    url: `${process.env.REACT_APP_API}/v1/salesRep/${data._id}`,
    method: "put",
    data,
    showSuccessNotification: true,
    successMessage: "Successfully updated profile",
  });
};

const updatePassword = (data) => {
  return request({
    url: `/v1/auth/updatepassword`,
    method: "post",
    data,
    showSuccessNotification: true,
    successMessage: "Successfully updated account password",
  });
};

const getAllTimezones = () => {
  return request({ url: `/v1/timezone` });
};

const logout = ({ allDevice = false }) =>
  request({
    url: `${process.env.REACT_APP_API}/v2/mastermind/logout`,
    method: "POST",
    data: {
      allDevice,
    },
  });

export const useLogout = () => {
  const { user } = useSelector((state) => state.authentication);
  return useMutation(() => logout({ userHash: user?.userHash }), {
    retry: false,
    onSuccess: () => {
      dispatch(logoutAction());
    },
  });
};

const getUpdatedUserAndToken = () =>
  request({
    url: `${process.env.REACT_APP_API}/v2/token/mastermind`,
    method: "GET",
  });

export const useGetUpdatedUserAndToken = () => {
  const { wasLoggedIn } = useSelector((state) => state.authentication);

  return useQuery("update-user-and-token", () => getUpdatedUserAndToken(), {
    retry: false,
    enabled: !!wasLoggedIn,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      dispatch(loginAction(data.data));
    },
    onError: () => {
      localStorage.setItem("was-logged-in", false);
      localStorage.setItem("is-admin", false);
    },
  });
};

export const useLogin = () => {
  return useMutation(login, {
    retry: false,
    onSuccess: (data) => {
      dispatch(loginAction(data.data));
    },
  });
};

export const useSignUp = () => {
  return useMutation(signUp, {
    retry: false,
  });
};

export const useInvite = () => {
  return useMutation(invite, {
    retry: false,
  });
};

export const useVerifyEmail = () => {
  return useMutation(verifyEmail, {
    retry: false,
  });
};

export const useResendEmail = () => {
  return useMutation(resendVerifyEmail, {
    retry: false,
  });
};

export const useProfileUpdate = () => {
  return useMutation(profileUpdate, {
    retry: false,
  });
};

export const useUpdatePassword = () => {
  return useMutation(updatePassword, {
    retry: false,
  });
};

export const useFetchTimezones = () => {
  return useQuery(["timezones"], () => getAllTimezones(), {
    retry: false,
  });
};

export const useRetrictedSignup = () => {
  return useMutation(restrictedSignup, {
    retry: false,
  });
};

const isClickNotifyTimezoneBanner = (hash) => {
  return request({
    url: `/v1/salesRep/preference/${hash}`,
    method: "put",
  });
};

export const useIsClickNotifyTimezoneBanner = () => {
  return useMutation(isClickNotifyTimezoneBanner, {
    retry: false,
  });
};
