import { APP_NAME } from 'configs/AppConfig';
import logo from "assets/images/Logo/dialworks-logo.png";
// import { useHistory } from "react-router-dom"

const Logo = () => {
    // const history = useHistory();
    const redirect = () => {
        // history.push(`${APP_PREFIX_PATH}/options`)
    }
    return (
        <div className="logo" style={{ width: '250px', }}>
            <img src={logo} alt={`${APP_NAME} logo`} style={{ width: '200px', cursor: 'pointer' }} onClick={redirect} />
        </div>
    )
}

export default Logo