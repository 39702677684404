import React from 'react'
import { Button } from "antd";
import { Link } from 'react-router-dom'
import Logo from "components/layout-components/Header/Logo"
import img from "assets/images/img-21.png"

const ErrorVeiw = ({ error, resetErrorBoundary }) => {
    console.error(error)
    return (
        <div className="h-100 bg-white">
            <div className="container-fluid d-flex flex-column justify-content-between h-100 px-md-4 pb-md-4 pt-md-1">
                <div>
                    <Logo />
                </div>
                <div className="container">
                    <div className="text-center mb-5">
                        <img className="img-fluid" src={img} alt="" />
                        <h1 className="font-weight-bold mb-4">Sorry, something went wrong{" "}
                            {process.env.REACT_APP_TYPE === "development" ?
                                error.message : null}
                        </h1>
                        <Link
                            to='#'
                            onClick={() => {
                                resetErrorBoundary();
                            }}
                        >
                            <Button type="primary">Back to Home</Button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ErrorVeiw

