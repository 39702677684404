import { createContext, useContext, useState } from "react";

const LayoutContext = createContext();

export const useLayout = () => useContext(LayoutContext);

export const LayoutProvider = ({ children }) => {
  const [isNavCollapsed, setIsNavCollapsed] = useState(
    () => localStorage.getItem('nav-collapsed')
      ? JSON.parse(localStorage.getItem('nav-collapsed'))
      : false
  );

  const toggleNav = () => {
    setIsNavCollapsed(currStatus => {
      localStorage.setItem('nav-collapsed', !currStatus);
      return !currStatus;
    });
  }

  return (
    <LayoutContext.Provider value={{ isNavCollapsed, toggleNav }}>
      { children }
    </LayoutContext.Provider>
  )
};
