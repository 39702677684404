import { createSlice } from "@reduxjs/toolkit";
import { queryClient } from "react-query-client";

const initialState = {
  user: {},
  isAdmin: localStorage.getItem("is-admin")
    ? JSON.parse(localStorage.getItem("is-admin"))
    : false,
  token: null,
  wasLoggedIn: localStorage.getItem("was-logged-in")
    ? JSON.parse(localStorage.getItem("was-logged-in"))
    : false,
};

export const authSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    login: (state, action) => {
      const data = action.payload;
      const isAdmin = data.user?.role === 2 ? false : true;
      localStorage.setItem("is-admin", isAdmin);
      localStorage.setItem("was-logged-in", true);
      return {
        ...state,
        user: data.user,
        token: data.accessToken,
        wasLoggedIn: true,
      };
    },
    logout: () => {
      localStorage.clear();
      queryClient.clear();
      return initialState;
    },
    updateUser: (state, action) => {
      const data = action.payload;
      const isAdmin = data.user?.role === 2 ? false : true;
      localStorage.setItem("was-logged-in", true);
      localStorage.setItem("is-admin", isAdmin);
      return {
        ...state,
        user: {
          ...state.user,
          ...data.user,
        },
        wasLoggedIn: true,
        token: data.accessToken,
      };
    },
  },
});

export const { login, logout, updateUser } = authSlice.actions;

export default authSlice.reducer;
