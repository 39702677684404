import Loading from "components/shared-components/Loading";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";
import { lazy, Suspense, memo } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

function LoaderRedirect() {
  const { token, user } = useSelector((state) => state.authentication);

  if (token) {
    if (user?.role === 0) {
      return (
        <Redirect
          from={`${AUTH_PREFIX_PATH}`}
          to={`${APP_PREFIX_PATH}/dashboard`}
        />
      );
    } else if (user?.role === 1) {
      return (
        <Redirect
          from={`${AUTH_PREFIX_PATH}`}
          to={`${APP_PREFIX_PATH}/dashboard`}
        />
      );
    } else {
      return (
        <Redirect
          from={`${AUTH_PREFIX_PATH}`}
          to={`${APP_PREFIX_PATH}/partners`}
        />
      );
    }
  }
  return (
    <Redirect from={`${AUTH_PREFIX_PATH}`} to={`${AUTH_PREFIX_PATH}/login`} />
  );
}

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Switch>
        <Route
          exact
          path={`${AUTH_PREFIX_PATH}/login`}
          component={lazy(() => import(`./Login`))}
        />
        <LoaderRedirect />
      </Switch>
    </Suspense>
  );
};

export default memo(AppViews);
