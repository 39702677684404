import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { createBrowserHistory } from "history";

import Pages from "views"
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { ErrorBoundary } from 'react-error-boundary'
import ErrorView from "views/error-view"

const myErrorHandler = (error, info) => {
  console.error("error", error);
  console.error("info", info);
}

const history = createBrowserHistory();

const themes = {
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <ErrorView error={error} resetErrorBoundary={resetErrorBoundary} />
  )
}

function resetErrorBoundary() {
  window.location.reload();
}

function App() {
  return (
    <Router history={history}>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={myErrorHandler}
        onReset={resetErrorBoundary}
      >
        <div className="App">
          <ThemeSwitcherProvider themeMap={themes} defaultTheme='light' insertionPoint="styles-insertion-point">
            <Switch>
              <Route path="/" component={Pages} />
            </Switch>
          </ThemeSwitcherProvider>
        </div>
      </ErrorBoundary>
    </Router>
  );
}

export default App;
