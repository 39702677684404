import { LogoutOutlined, LoadingOutlined } from "@ant-design/icons";
import { Avatar, Col, Dropdown, Menu, Row } from "antd";
import gravatarUrl from "gravatar-url";
import { useSelector } from "react-redux";
import { useLogout } from "services/auth-services";

function NavProfile() {
  const { user } = useSelector((state) => state.authentication);
  const { mutate, isLoading } = useLogout();

  const handleSignOut = () => {
    mutate();
  };

  const link = gravatarUrl(user.email, {
    size: 200,
    default: "https://dialworks.s3.us-east-2.amazonaws.com/avatar-150.png",
  });

  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Row gutter={8}>
            <Col>
              <Avatar src={link} />
            </Col>
            <Col>
              <div className="ml-3">
                <h4 className="mb-0">{user?.fName + " " + user?.lName}</h4>
                <span className="text-muted">{user?.email}</span>
                <br />
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          <Menu.Item key={3} onClick={handleSignOut}>
            <span>
              {isLoading ? <LoadingOutlined /> : <LogoutOutlined />}
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );

  return (
    <div className="fixed right-20">
      <Dropdown
        placement="bottomRight"
        overlay={profileMenu}
        trigger={["click"]}
      >
        <Menu className="d-flex align-item-center" mode="horizontal">
          <Menu.Item key="profile">
            <Avatar src={link} />
          </Menu.Item>
        </Menu>
      </Dropdown>
    </div>
  );
}

export default NavProfile;
