import { Menu } from "antd";
import navigationConfig from "configs/NavigationConfig";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Icon from "../../Icon";

const setDefaultOpen = (key) => {
  let keyList = [];
  let keyString = "";
  if (key) {
    const arr = key.split("-");
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }
  return keyList;
};

function MenuContent({ routeInfo }) {
  const { user } = useSelector(state => state.authentication);

  return (
    <Menu
      mode="inline"
      style={{ height: "100%", borderRight: 0 }}
      selectedKeys={[routeInfo?.key, routeInfo?.highlightOnKeySelect]}
      defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
    >
      <>
        {navigationConfig
            .filter(({ hidden }) => !hidden)
            .map((menu) =>
              (menu.submenu.length > 0) ? (
                <Menu.ItemGroup key={menu.key} title={menu.title}>
                  {menu.submenu.map((submenuFirst) =>
                    submenuFirst.submenu.length > 0 ? (
                      <>
                        <Menu.SubMenu
                          icon={
                            submenuFirst.icon ? (
                              <Icon type={submenuFirst?.icon} />
                            ) : null
                          }
                          key={submenuFirst.key}
                          title={submenuFirst.title}
                        >
                          {submenuFirst.submenu.map((subMenuSecond) => (
                            <Menu.Item
                              key={subMenuSecond.key}
                              className="m-0"
                              hidden={!subMenuSecond?.canAccess?.includes(user?.role)}
                            >
                              {subMenuSecond.icon ? (
                                <Icon type={subMenuSecond?.icon} />
                              ) : null}
                              <span>{subMenuSecond.title}</span>
                              <Link to={subMenuSecond.path} />
                            </Menu.Item>
                          ))}
                        </Menu.SubMenu>
                      </>
                    ) : (
                      <Menu.Item
                        key={submenuFirst.key}
                        className="m-0"
                        hidden={!submenuFirst?.canAccess?.includes(user?.role)}
                      >
                        {submenuFirst.icon ? (
                          <Icon type={submenuFirst.icon} />
                        ) : null}
                        <span>{submenuFirst.title}</span>
                        <Link to={submenuFirst.path} />
                      </Menu.Item>
                    )
                  )}
                </Menu.ItemGroup>
              ) : (
                <Menu.Item
                  key={menu.key}
                  className="m-0"
                  hidden={!menu?.canAccess?.includes(user?.role)}
                >
                  {menu.icon ? <Icon type={menu?.icon} /> : null}
                  <span>{menu?.title}</span>
                  {menu.path ? <Link to={menu.path} /> : null}
                </Menu.Item>
              )
            )}
      </>
    </Menu>
  );
}

export default MenuContent;
