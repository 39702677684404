import React from "react";
import { Layout } from "antd";
import { Scrollbars } from "react-custom-scrollbars";
import { SIDE_NAV_WIDTH } from "constants/ThemeConstant";
import MenuContent from "./MenuContent";

function SideNav({ routeInfo, collapsed, toggleNav }) {
  return (
    <Layout.Sider style={{ background: 'white', height: '100vh' }}
      breakpoint="lg"
      className="side-nav"
      collapsed={collapsed}
      onCollapse={toggleNav}
      width={SIDE_NAV_WIDTH}
    >
      <Scrollbars autoHide>
        <MenuContent routeInfo={routeInfo} />
      </Scrollbars>
    </Layout.Sider>
  );
}

export default SideNav;
