import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AppLayout from "layout/AppLayout";
import AuthLayout from "layout/AuthLayout";
import { useSelector } from "react-redux";
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from "configs/AppConfig";
import Loading from "components/shared-components/Loading";
import { LayoutProvider } from "contexts/LayoutContext";
import { useGetUpdatedUserAndToken } from "services/auth-services";

function RouteInterceptor({ children, isAuthenticated, isLoading, ...rest }) {
  console.log("tst", isLoading);
  if (isLoading) {
    return <Route {...rest} render={() => <Loading cover="page" />} />;
  }
  if (isAuthenticated) {
    return <Route {...rest} render={() => children} />;
  }

  return (
    <Route
      {...rest}
      render={({ location }) => (
        <Redirect
          to={{
            pathname: `${AUTH_PREFIX_PATH}/login`,
            state: { from: location },
          }}
        />
      )}
    />
  );
}

function Pages() {
  const { isLoading } = useGetUpdatedUserAndToken();
  const { token } = useSelector((state) => state.authentication);
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to={`${AUTH_PREFIX_PATH}`} />
      </Route>
      <Route path={`${AUTH_PREFIX_PATH}`}>
        <AuthLayout />
      </Route>
      <RouteInterceptor
        path={APP_PREFIX_PATH}
        isAuthenticated={token}
        isLoading={isLoading}
      >
        <LayoutProvider>
          <AppLayout />
        </LayoutProvider>
      </RouteInterceptor>
      {/* <Route exact path="/">
                <Redirect to={`${AUTH_PREFIX_PATH}/login`} />
            </Route>
            {/* <Route path={`${AUTH_PREFIX_PATH}`}>
                    <AuthLayout />
            </Route> */}
      {/* <LayoutProvider>
                <Route path={`${APP_PREFIX_PATH}`}>
                    <AppLayout />
                </Route>
            </LayoutProvider>  */}
    </Switch>
  );
}

export default Pages;
