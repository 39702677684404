import Loading from "components/shared-components/Loading";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";
import { lazy, Suspense, memo } from "react";
import { useSelector } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";

function LoaderRedirect() {
  const { token, user } = useSelector((state) => state.authentication);
  if (token) {
    if (user?.role === 0) {
      return (
        <Redirect
          from={`${AUTH_PREFIX_PATH}`}
          to={`${APP_PREFIX_PATH}/dashboard`}
        />
      );
    } else if (user?.role === 1) {
      return (
        <Redirect
          from={`${AUTH_PREFIX_PATH}`}
          to={`${APP_PREFIX_PATH}/dashboard`}
        />
      );
    } else {
      return (
        <Redirect
          from={`${AUTH_PREFIX_PATH}`}
          to={`${APP_PREFIX_PATH}/partners`}
        />
      );
    }
  }
  return (
    <Redirect from={`${AUTH_PREFIX_PATH}`} to={`${AUTH_PREFIX_PATH}/login`} />
  );
}

export const AppViews = ({ routeInfo }) => {
  // const { user } = useSelector((state) => state.authentication);
  // const history = useHistory();

  // if (!routeInfo?.canAccess?.includes(user?.role)) {
  //   // user will be redirected to their respective home page accoring to role
  //   history.push(`${AUTH_PREFIX_PATH}/login`);
  // }

  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/dashboard`}
          component={lazy(() => import(`./dashboard-view/`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/sales-reps/:id`}
          component={lazy(() => import(`./sales-reps-view/Details/`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/sales-reps`}
          component={lazy(() => import(`./sales-reps-view/`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/partners`}
          component={lazy(() => import(`./partners-view/`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/mails`}
          component={lazy(() => import(`./mail-view/`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/unsuccessful-calls`}
          component={lazy(() => import(`./calls-logs-view`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/signup-attempt`}
          component={lazy(() => import(`./signup-attempt-view`))}
        />
        <LoaderRedirect />
      </Switch>
    </Suspense>
  );
};

export default memo(AppViews);
