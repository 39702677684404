import React from 'react'
import { Layout, Typography } from "antd"
import NavProfile from './NavProfile';
import Logo from "./Logo"

export const PageHeader = () => {
  return (
    <Layout.Header className={`app-header`}>
      <div className='d-flex justify-between'>
        <div>
          <Logo />
        </div>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          width: 'calc(100vw - 250px)',
          justifyContent: 'space-between',
        }}>
          <Typography.Title
            level={4}
            className="m-0"
            style={{ paddingLeft: "1.6em" }}
          >
            Mastermind
          </Typography.Title>
          <NavProfile />
        </div>
      </div >
    </Layout.Header >
  )
};

export default PageHeader;
