import {
  CustomerServiceOutlined,
  DashboardOutlined,
  UserOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { MastermindRole } from "constants/BackendEnums";

const dashboard = [
  {
    key: "dashboard",
    path: `${APP_PREFIX_PATH}/dashboard`,
    title: "Dashboard",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [],
    canAccess: [MastermindRole.SuperAdmin, MastermindRole.Admin],
  },
];

const salesRep = [
  {
    key: "sales-reps",
    path: `${APP_PREFIX_PATH}/sales-reps`,
    title: "Sales Reps",
    breadcrumb: false,
    icon: UserOutlined,
    submenu: [],
    canAccess: [MastermindRole.SuperAdmin, MastermindRole.Admin],
  },
  {
    key: "sales-reps-details",
    path: `${APP_PREFIX_PATH}/sales-reps/:id`,
    title: "Sales Reps",
    breadcrumb: false,
    submenu: [],
    hidden: true,
    highlightOnKeySelect: "sales-reps",
    canAccess: [MastermindRole.SuperAdmin, MastermindRole.Admin],
  },
];

const partner = [
  {
    key: "partners",
    path: `${APP_PREFIX_PATH}/partners`,
    title: "Partners",
    breadcrumb: false,
    icon: CustomerServiceOutlined,
    submenu: [],
    canAccess: [
      MastermindRole.SuperAdmin,
      MastermindRole.Admin,
      MastermindRole.PartnerLead,
    ],
  },
];

const mail = [
  {
    key: "mails",
    path: `${APP_PREFIX_PATH}/mails`,
    title: "Mails",
    breadcrumb: false,
    icon: MailOutlined,
    submenu: [],
    canAccess: [
      MastermindRole.SuperAdmin,
      MastermindRole.Admin,
      //MastermindRole.PartnerLead,
    ],
  },
];

const unsuccessfulCalls = [
  {
    key: "unsuccessful-calls",
    path: `${APP_PREFIX_PATH}/unsuccessful-calls`,
    title: "Unsuccessful calls",
    breadcrumb: false,
    icon: PhoneOutlined,
    submenu: [],
    canAccess: [MastermindRole.SuperAdmin, MastermindRole.Admin],
  },
];
const signupAttempt = [
  {
    key: "signup-attempt",
    path: `${APP_PREFIX_PATH}/signup-attempt`,
    title: "Signup attempt",
    breadcrumb: false,
    icon: UserOutlined,
    submenu: [],
    canAccess: [MastermindRole.SuperAdmin, MastermindRole.Admin],
  },
];

const navigationConfig = [
  ...dashboard,
  ...salesRep,
  ...partner,
  ...mail,
  ...unsuccessfulCalls,
  ...signupAttempt,
];

export default navigationConfig;
