/**
 * String values for the enums from the backend.
 */
export const Status = Object.freeze({
  0: "Deleted",
  1: "Active",
  2: "Archived",
});

export const SalesRepRole = Object.freeze({
  0: "Owner",
  1: "Admin",
  2: "Team Member",
});

export const MastermindRole = Object.freeze({
  SuperAdmin: 0,
  Admin: 1,
  PartnerLead: 2,
});

export const CompanySourceRole = Object.freeze({
  1: "DialWorks",
  2: "JustCall",
});

export const SalesRepMode = Object.freeze({
  1: "Standard",
  2: "Lite",
});

// export const PartnerActivityStatus = Object.freeze({
//   1: 'Available',
//   2: 'Answered Call',
//   3: 'Completed Call',
//   4: 'Busy',
//   5: 'Unavailable',  // XXX: Not in backend
// });

export const PartnerActivityStatus = Object.freeze({
  Unavailable: 0,
  Available: 1,
  Ringing: 2,
  InCall: 3,
  WritingFeeback: 4,
});
