import { Layout } from "antd";
import PageHeader from "components/layout-components/Header";
import SideNav from "components/layout-components/SideNav";
import Loading from "components/shared-components/Loading";
import navigationConfig from "configs/NavigationConfig";
import {
  SIDE_NAV_COLLAPSED_WIDTH,
  SIDE_NAV_WIDTH
} from "constants/ThemeConstant";
import { useLayout } from "contexts/LayoutContext";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { useLocation } from "react-router-dom";
import utils from "utils";
import AppViews from "views/app-views";

const { Content } = Layout;

const AppLayout = () => {
  const location = useLocation();
  const { isNavCollapsed, toggleNav } = useLayout();
  const { status } = useThemeSwitcher();
  const currentRouteInfo = utils.getRouteInfo(navigationConfig, location.pathname);

  if (status === "loading") {
    return <Loading cover="page" />;
  }

  const getLayoutGutter = () => isNavCollapsed
    ? SIDE_NAV_COLLAPSED_WIDTH
    : SIDE_NAV_WIDTH;

  const getLayoutDirectionGutter = () => ({ paddingLeft: getLayoutGutter() });

  return (
    <>
      <Layout>
        <PageHeader
          display={currentRouteInfo?.breadcrumb}
          title={currentRouteInfo?.title}
        />
        <Layout className="app-container">
          <SideNav
            toggleNav={toggleNav}
            collapsed={isNavCollapsed}
            routeInfo={currentRouteInfo}
          />
          <Layout className="app-layout" style={getLayoutDirectionGutter()}>
            <div className="app-content">
              <Content>
                <AppViews routeInfo={currentRouteInfo} />
              </Content>
            </div>
          </Layout>
        </Layout>
      </Layout>
    </>
  )
}

export default AppLayout
