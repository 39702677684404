import axios from "axios";
import { notification } from "antd";
import { login, logout } from "redux/authentication/auth";
import { store, dispatch } from "redux/store";

const client = axios.create({ baseURL: process.env.REACT_APP_API });

export const request = ({
  isNotificationDisabled = false,
  showSuccessNotification = false,
  successMessage = "",
  errorMessage = { fromApi: false, message: null },
  ...options
}) => {
  const token = store.getState().authentication.token;

  client.defaults.headers.common.Authorization = `Bearer ${token}`;
  client.defaults.headers.post.ContentType = "application/json";
  client.defaults.withCredentials = true;

  const onSuccess = (response) => {
    let notificationParam = {
      message: "Success",
      description: successMessage,
      placement: "bottomLeft",
    };

    if (showSuccessNotification) {
      notification.success(notificationParam);
    }

    return response;
  };

  const onError = (error) => {
    let notificationParam = {
      message: "Error",
      description: "Oops! Something went wrong.",
      placement: "bottomLeft",
    };
    // console.log(JSON.stringify(error.response))
    // Remove token and redirect
    if (error.response.status === 401) {
      client
        .get(`${process.env.REACT_APP_API}/v2/token/mastermind`)
        .then(({ data }) => {
          dispatch(login(data));
          return request({
            isNotificationDisabled,
            successMessage,
            errorMessage,
            ...options,
          });
        })
        .catch(() => {
          notificationParam.description = "Please login again";
          dispatch(logout());
        });
      // console.log("Error message: " + error.response);
    }

    if (error.response.status === 400) {
      notificationParam.message = error.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
    }

    if (error.response.status === 404) {
      notificationParam.message = error.response?.data?.message
        ? error.response.data.message
        : "Not Found";
    }

    if (error.response.status === 500) {
      notificationParam.message = "Internal Server Error";
    }

    if (error.response.status === 508) {
      notificationParam.message = "Time Out";
    }

    if (errorMessage.fromApi) {
      notificationParam.message = error.response.data.message;
    }

    if (errorMessage.message) {
      notificationParam.message = errorMessage.message;
    }

    if (!isNotificationDisabled && error.response.status !== 401 && token) {
      notification.error(notificationParam);
    }

    return Promise.reject(error.response.data);
  };
  return client(options).then(onSuccess).catch(onError);
};
